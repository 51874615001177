import axios from "axios";
import { config } from "../../api-helper/api-header-configs";
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_applications = async () => {
    const response = await axios.get(`${baseURL}/application`)
    return response.data;
}
export const get_applications_against_organisation = async (organisationId) => {
    const response = await axios.get(`${baseURL}/applications-by-organization/${organisationId}`)
    return response.data;
}
export const get_applications_details = async (applicationId) => {
    const response = await axios.get(`${baseURL}/application/${applicationId}`)
    return response.data;
}
export const get_organisation_tenant = async (organizationId, userId, apiUrl) => {
    const response = await axios.post(`https://${apiUrl}/api/get-organization-tenant`, {
        organization_id: organizationId,
        user_id: userId
    });
    return response.data;
};
// Edit Organisation
export const update_application = async (id:number,formData:object, apiUrl) => {
    const response = await axios.patch(`https://${apiUrl}/api/tenants/` + id, formData, config)
  return response.data;
}
export const check_application_tenant = async (values) => {
    let response = await axios.post(`${baseURL}/check-application-tenant`, values, config);
    return response.data;
}
export const add_application_in_organisation = async (values) => {
    let response = await axios.post(`${baseURL}/application-store-for-organisation`, values);
    return response.data;
}
export const add_tenant_general_settings = async (values) => {
    let response = await axios.post(`${baseURL}/add-tenant-general-setting`, values, config);
    return response.data;
}
export const edit_tenant_general_settings = async (formData:object) => {
    let response = await axios.post(`${baseURL}/update-tenant-general-setting`, formData, config);
    return response.data;
}