import { useState } from "react";
import * as Yup from "yup";
import { IUpdatePassword, updatePassword } from "../../../modules/accounts/components/settings/SettingsModel";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { changePassword } from "../api/accountApi";
import { toast } from "react-toastify";

const changePasswordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required"),
    newPassword: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("New Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            "New Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
    passwordConfirmation: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Confirm Password is required")
        .oneOf([Yup.ref("newPassword")], "New and Confirm Password must match"),
});
function ChangePassword() {
    const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const formik = useFormik<IUpdatePassword>({
        initialValues: {
            ...passwordUpdateData,
        },
        validationSchema: changePasswordValidationSchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });
    const mutation = useMutation(
        async (values: IUpdatePassword) => changePassword(values.currentPassword, values.newPassword, values.passwordConfirmation),
        {
            onSuccess: (data) => {
                toast.success(data.message, {
                    theme: "colored",
                });
                formik.resetForm();
            },
            onError: (error: any) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                });
            },
        }
    );
    return (
        <div className="card mb-5 mb-xl-10">
            {/* Password Section */}
            <div
                className="card-header border-0 "
                role="button"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Change Password</h3>
                </div>
            </div>
            <div id="kt_account_profile_details">
                <form onSubmit={formik.handleSubmit} noValidate className="form">
                    <div className="card-body border-top p-9">
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                <span className="required"> Password</span>
                            </label>

                            <div className="col-lg-8 fv-row">
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="Current Password"
                                        value={formik.values.currentPassword? formik.values.currentPassword : ''}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="currentPassword"
                                    />
                                    <span
                                        className={`input-group-text ${showPassword
                                            ? "text-primary cursor-pointer border-0"
                                            : "text-muted cursor-pointer border-0"
                                            }`}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </span>
                                </div>

                                {formik.touched.currentPassword && formik.errors.currentPassword && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.currentPassword}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                <span className="required"> New Password</span>
                            </label>

                            <div className="col-lg-8 fv-row">
                                <div className="input-group">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="New Password"
                                        value={formik.values.newPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="newPassword"
                                    />
                                    <span
                                        className={`input-group-text ${showNewPassword
                                            ? "text-primary cursor-pointer border-0"
                                            : "text-muted cursor-pointer border-0"
                                            }`}
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                    >
                                        {showNewPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </span>
                                </div>

                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.newPassword}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                <span className="required">Confirm Password</span>
                            </label>
                            <div className="col-lg-8 fv-row">
                                <div className="input-group">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="Confirm Password"
                                        value={formik.values.passwordConfirmation}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="passwordConfirmation"
                                    />
                                    <span
                                        className={`input-group-text ${showConfirmPassword
                                            ? "text-primary cursor-pointer border-0"
                                            : "text-muted cursor-pointer border-0"
                                            }`}
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </span>
                                </div>
                                {formik.touched.passwordConfirmation &&
                                    formik.errors.passwordConfirmation && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.passwordConfirmation}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={mutation.isLoading}
                        >
                            {!mutation.isLoading ? (
                                "Save Changes"
                            ) : (
                                <span
                                    className="indicator-progress d-block"
                                >
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword