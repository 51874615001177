import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { OrdersList } from '../pages/orders/OrdersList'
import ChangePassword from '../pages/account/components/ChangePassword'
import AddOrganisation from '../pages/organisations/components/AddOrganisation'
import OrganisationsList from '../pages/organisations/components/OrganisationsList'
import ViewOrganisation from '../pages/organisations/components/ViewOrganisation'
import EditOrganisation from '../pages/organisations/components/EditOrganisation'
import ApplicationsList from '../pages/applications/components/ApplicationsList'
import AddApplication from '../pages/applications/components/AddApplication'
import EditApplication from '../pages/applications/components/EditApplication'
import AddUser from '../pages/user-management/users/components/AddUser'
import OrganisationUsers from '../pages/organisations/components/OrganisationUsers'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/organisations' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path="/organisations/create-organisation" element={<AddOrganisation />} />
        <Route path="/organisations" element={<OrganisationsList />} />
        <Route path="/organisations/view-organisations" element={<ViewOrganisation />} />
        <Route path="/organisations/edit-organisation" element={<EditOrganisation />} />
        <Route path="/organisations/applications" element={<ApplicationsList />} />
        <Route path="/organisations/applications/subscribe-new-application" element={<AddApplication />} />
        <Route path="/organisations/applications/edit-application" element={<EditApplication />} />
        {/* <Route path='/user-management/users/add-user' element={<AddUser />} /> */}
        <Route path='/organisations/organisation-users' element={<OrganisationUsers />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path="/orders-list" element={<OrdersList />} />
        <Route path="/change-password" element={<ChangePassword />} />
        {/* <Route path="/orders/order-add" element={<OrdersList />} />
        <Route path="/orders/order-edit" element={<OrdersList />} />
        <Route path="/orders/order-logs" element={<OrdersList />} />
        <Route path="/orders/order-logs" element={<OrdersList />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='change-password/*'
          element={
            <SuspensedView>
              <ChangePassword />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Redirect to organisations if no page found */}
        <Route path='*' element={<Navigate to='organisations' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
