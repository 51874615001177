import React, { useEffect } from 'react';

const StoreToken: React.FC = () => {
    useEffect(() => {
        console.log('store_token.html loaded');
        window.addEventListener('message', (event) => {
            console.log('Received  from:', event.origin);
            console.log(' data:', event.data);
            if (event.origin === 'https://customer2.wrikooffice.staginghosts.com') {
                const { token, expiration } = event.data;
                localStorage.setItem('kt-auth-react-v', JSON.stringify(token));
                localStorage.setItem('kt-auth-react-v-expiration', expiration);
                console.log('Token stored in localStorage:', token);
                const source = event.source as Window | null; 
                if (source) {
                    source.postMessage('Token stored successfully', event.origin);
                } else {
                    console.warn('No event source to send acknowledgment');
                }
            } else {
                console.warn('Received message from unexpected origin');
            }
        });
    }, []);

    return (
        <div>
            <h1>Store Token</h1>
        </div>
    );
};

export default StoreToken;
