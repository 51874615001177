const storedData = localStorage.getItem('kt-auth-react-v')
const parsedData = storedData ? JSON.parse(storedData) : null
const api_token = parsedData?.api_token || null

export const config = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: api_token ? `Bearer ${api_token}` : undefined,
  },
}
export const config_multipart = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
}