import React, { useEffect, useRef, useState } from 'react'
import { OrganisationListInterface, applicationsInterface } from '../../../modules/accounts/components/settings/SettingsModel'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useMutation, useQuery } from 'react-query'
import { add_application_in_organisation, get_all_applications } from '../api/applicationsApi'
import { toast } from 'react-toastify'
import axios from 'axios'
// interface User {
//     access_token: string;
// }

function ApplicationsList() {
    const [applicationList, setApplicationList] = useState<applicationsInterface[]>([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface | null>();
    const [selectedApplication, setSelectedApplication] = useState<applicationsInterface>();
    const [applicationsArray, setApplicationsArray] = useState<number[]>([]);
    const location = useLocation();
    // const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const organisation = (location.state as { organisation: OrganisationListInterface }).organisation;
    const navigate = useNavigate();

    useEffect(() => {
        if (organisation) {
            setSelectedOrganisation(organisation as OrganisationListInterface);
        }
    }, [organisation]);

    const { data: applicationData } = useQuery('applications', get_all_applications, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (applicationData) {
            setApplicationList(applicationData.data as applicationsInterface[]);
        }
    }, [applicationData]);

    const handleAddApplication = useMutation(add_application_in_organisation, {
        onSettled: (data, error) => {
            if (!error) {
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            } else {
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
            }
        },
    });

    const handleSelectApplication = (applicationId: number) => {
        let updatedApplications;
        if (applicationsArray.includes(applicationId)) {
            updatedApplications = applicationsArray.filter(item => item !== applicationId);
        } else {
            updatedApplications = [...applicationsArray, applicationId];
        }
        setApplicationsArray(updatedApplications);
    };

    // const storeToken = (token, expirationTime) => {
    //     // Store in localStorage
    //     localStorage.setItem('tempToken', token);
    //     localStorage.setItem('tempTokenExpiration', Date.now() + expirationTime);

    //     if (iframeRef.current) {
    //         try {
    //             iframeRef.current.contentWindow?.postMessage(
    //                 { token, expiration: Date.now() + expirationTime },
    //                 'http://localhost:3011'
    //             );
    //             console.log('Message sent to iframe');
    //         } catch (error) {
    //             console.error('Error sending message to iframe:', error);
    //         }
    //     }
    // };

    const handleApplicationClick = (application: applicationsInterface) => {
        if (application.is_tenant_created === 0) {
            console.log("clicked");
            const url = "http://localhost:3011";
            window.open(url, '_blank');
            // const user: User | null = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}');
            // const token = user?.access_token;
            // const expirationTime = 3600000; // 1 hour in milliseconds
            // storeToken(token, expirationTime);
        }
    };

    const handleSubscribeApplication = (organisation: any, application: any) => {
        navigate("/organisations/applications/subscribe-new-application", { state: { organisation: organisation, application: application } });
    };

    const handleApplicationEdit = (organisation: any, application: any) => {
        navigate("/organisations/applications/edit-application", { state: { organisation: organisation, application: application } });
    };
    // const [isLoading, setIsLoading] = useState(true);
    // const handleIframeLoad = () => {
    //     setIsLoading(false); // Hide loading screen
    //     console.log(`Iframe loaded.`);
    // };

    return (
        <>
            <div className='card-title mb-3 mx-3'>
                <h1 className='fw-bolder m-0'>All Applications</h1>
            </div>
            {selectedOrganisation ? (
                <>
                    <Card className='mb-3'>
                        <Card.Header>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>My Apps </h3>
                            </div>
                        </Card.Header>
                        <Card.Body className='row'>
                            {organisation.applications.map((application) => (
                                <React.Fragment key={application.id}>
                                    {application.is_tenant_created === 1 ? (
                                        <Card className={`col-lg-3 col-md-5 application-card-list ms-3 mt-2`}>
                                            <div className='position-relative'>
                                                <div
                                                    className='btn btn-icon btn-sm btn-active-light-primary position-absolute top-0 end-0 mt-2'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#kt_modal_1'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedApplication(application);
                                                    }}
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr061.svg'
                                                        className='svg-icon svg-icon-2x'
                                                    />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Text>
                                                    <div className='d-flex justify-content-center mt-9'>
                                                        {application && application.logo === null ? (
                                                            <img
                                                                alt='Logo'
                                                                src={toAbsoluteUrl('/media/logos/wriko_central_icon.png')}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        ) : (
                                                            <img
                                                                alt='Logo'
                                                                src={application.logo}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        )}
                                                    </div>
                                                </Card.Text>
                                                <Card.Title className='d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark'>{application.name} {' '}</Card.Title>
                                                <div className='d-none justify-content-center mt-auto application-setting-button'>
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                                        className='btn btn-icon btn-sm btn-active-secondary'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleApplicationEdit(organisation, application);
                                                        }}>
                                                        <KTIcon iconName='setting-2' className='fs-3 text-dark' />
                                                    </button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ) : (
                                        <h6>Please subscribe an application first</h6>
                                    )}
                                </React.Fragment>
                            ))}
                        </Card.Body>
                    </Card>

                    <Card className='mb-3'>
                        <Card.Header>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>More Apps</h3>
                            </div>
                        </Card.Header>
                        {/* {isLoading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                zIndex: 10
                            }}>
                                <p>Loading...</p>
                            </div>
                        )} */}
                        <Card.Body className='row'>
                            {organisation.applications.map((application) => (
                                <React.Fragment key={application.id}>
                                    {application.is_tenant_created == 0 ? (
                                        <Card className="col-lg-3 col-md-5 application-card-list ms-3 mt-2" onClick={() => handleApplicationClick(application)}>
                                            <Card.Body>
                                                {/* {iframeRef && (
                                                    <iframe
                                                        onLoad={() => handleIframeLoad()}
                                                        ref={iframeRef}
                                                        src="http://localhost:3011/store-token"
                                                        style={{ display: 'block' }} // Hidden iframe
                                                        title="Hidden Iframe"
                                                    />
                                                )} */}

                                                <div className="d-flex justify-content-center mt-3">
                                                    <img
                                                        alt="Logo"
                                                        src={
                                                            application && application.logo
                                                                ? application.logo
                                                                : toAbsoluteUrl('/media/logos/wriko_central_icon.png')
                                                        }
                                                        className="h-25px app-sidebar-logo-default theme-light-show"
                                                    />
                                                </div>

                                                <Card.Title
                                                    className="d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark"
                                                >
                                                    {application.name}
                                                </Card.Title>

                                                <div className="d-none justify-content-center mt-auto application-setting-button">
                                                    <button
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Edit Organisation"
                                                        className="btn btn-icon btn-dark btn-active-secondary btn-sm round-button"
                                                        onClick={() => handleSubscribeApplication(selectedOrganisation, application)}
                                                    >
                                                        <KTIcon iconName="plus" className="fs-3" />
                                                    </button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <div>No Applications found</div>
            )}
            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Unsubscribe Application</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedApplication ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Application Name: </span> {selectedApplication.name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Description: </span>{' '}
                                        {selectedApplication.description}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Organisation"
                                type='button'
                                // onClick={() => {
                                //     if (selectedOrganisation && selectedOrganisation.id) {
                                //         handleDeleteOrganisation.mutate(selectedOrganisation.id)
                                //     }
                                // }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Unsubscribe
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title'>Add Application</h3>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-6'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                    <h4>Select Applications</h4>
                                </label>
                                <div className='col-lg-12 fv-row row mx-2 px-9'>
                                    {applicationList.map((application) => (
                                        <Card className={`col-lg-4 col-md-5 application-card-list ms-5 mt-4 ${applicationsArray.includes(application.id) ? 'selected-application-card' : ''} `}
                                            onClick={() => handleSelectApplication(application.id)}
                                        >
                                            <Card.Body>
                                                <Card.Text>
                                                    <div className='d-flex justify-content-center mt-9'>
                                                        {application && application.logo === null ? (
                                                            <img
                                                                alt='Logo'
                                                                src={toAbsoluteUrl('/media/logos/wriko_central_icon.png')}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        ) : (
                                                            <img
                                                                alt='Logo'
                                                                src={application.logo}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        )}

                                                    </div>
                                                </Card.Text>
                                                <Card.Title className='d-flex justify-content-center text-primary cursor-pointer fs-7 text-dark' >{application.name} {' '}</Card.Title>
                                                <Card.Subtitle className="d-flex justify-content-center mb-2 text-muted fs-9">{application.description}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Add Application"
                                type='button'
                                onClick={() => {
                                    if (applicationsArray && applicationsArray.length > 0) {
                                        handleAddApplication.mutate({
                                            organisation_id: organisation.id,
                                            applications: applicationsArray
                                        })
                                    }
                                }}
                                className='btn btn-primary'
                                data-bs-dismiss='modal'
                            >
                                Add application
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApplicationsList