import axios from "axios";
import { config, config_multipart } from "../../api-helper/api-header-configs";
const baseURL = process.env.REACT_APP_API_URL;

// Add New organisation
export const add_organisation = async (values) => {
    let response = await axios.post(baseURL + "/organisations", values);
    return response.data;
}
// List of organisations
export const get_all_organisations = async () => {
    let response = await axios.get(baseURL + "/organisation_applications");
    return response.data;
}
// Delete organisation
export const delete_organisation= async (id:number) => {
    let response = await axios.delete(baseURL + "/organisations/"+id);
    return response.data;
}
// Edit Organisation
export const update_organisation = async (id:number,formData:object) => {
    const response = await axios.post(baseURL + "/organisations/update/" + id, formData, config_multipart)
  return response.data;
}
export const verify_user = async (email) => {
    let response = await axios.post(baseURL + "/user-exists", email);
    return response.data;
}
export const get_organisation_users = async (organisationId) => {
    const response = await axios.get(baseURL + "/list-organisation-user/" + organisationId);
    return response.data;
} 
export const delete_organisation_user = async (values) => {
    let response = await axios.post(baseURL + "/remove-organisation-user", values);
    return response.data;
}
export const add_organisation_user = async (values) => {
    let response = await axios.post(baseURL + "/add-organisation-user", values);
    return response.data;
}
// Edit Organisation user
export const update_organisation_user = async (userId:number,values:object) => {
    const response = await axios.post(baseURL + "/update-organisation-user/" + userId, values,)
  return response.data;
}