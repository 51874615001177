import React, { ChangeEvent, useEffect, useState } from 'react'
import { add_organisation } from '../api/OrganisationsApi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AddOrganisationInterface, addOrganisationInitialValues, applicationsInterface, countryInterface } from '../../../modules/accounts/components/settings/SettingsModel'
import { get_all_countries } from '../../../modules/auth/core/_requests'

const numberRegex = /^[+0-9]+$/;
const oranisationSchema = Yup.object().shape({
    name: Yup.string().required("Organisation Name is required"),
    email: Yup.string()
        .required('Email is required')
        .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/, 'Invalid email address'),
})
function AddOrganisation() {
    const [loading, setLoading] = useState(false)
    // const [applicationList, setApplicationList] = useState<applicationsInterface[]>([])
    const [countriesList, setCountriesList] = useState<countryInterface[]>([])
    const [whiteLogo, setWhiteLogo] = useState<File | null>(null);
    const [colorLogo, setColorLogo] = useState<File | null>(null);
    const [organisationIcon, setOrganisationIcon] = useState<File | null>(null);
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    // commenting for future use
    // const { data: applicationData } = useQuery('applications', get_all_applications, {
    //     refetchOnWindowFocus: false,
    // })
    // useEffect(() => {
    //     if (applicationData) {
    //         setApplicationList(applicationData.data as applicationsInterface[])
    //     }
    // }, [applicationData])
    const { data: countryData } = useQuery('countries', get_all_countries, {
        refetchOnWindowFocus: false,
    })
    useEffect(() => {
        if (countryData) {
            setCountriesList(countryData.data as countryInterface[])
        }
    }, [countryData])
    const mutation = useMutation(add_organisation, {
        onSettled: (data, error: any) => {
            setLoading(false); // Reset loading state
    
            if (!error) {
                toast.success(data?.message || 'Organisation added successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                navigate('/organisations');
                queryClient.invalidateQueries('organisations');
            } else {
                const errorMessage =
                    error?.response?.data?.message || error?.message || 'Something went wrong';
                
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            }
        },
    });
    
    const formik = useFormik<AddOrganisationInterface>({
        initialValues: addOrganisationInitialValues,
        validationSchema: oranisationSchema,
        onSubmit: (values) => {
            setLoading(true);    
            // Create a FormData object to handle file uploads
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('status', values.status.toString());
            formData.append('address_1', values.address_1);
            formData.append('address_2', values.address_2 || '');
            formData.append('town', values.town);
            formData.append('city', values.city);
            formData.append('country_id', values.country.toString());
            formData.append('post_code', values.postcode);
            formData.append('phone', values.phone || '');
            formData.append('mobile', values.mobile || '');
            formData.append('email', values.email);
            // values.applications.forEach(applicationId => {
            //     formData.append('applications[]', applicationId.toString());
            // });            
            
            formData.append('applications[0]', '1');
            if (colorLogo) formData.append('color_logo', colorLogo);
            if (whiteLogo) formData.append('white_logo', whiteLogo);
            if (organisationIcon) formData.append('icon', organisationIcon);
    
            mutation.mutate(formData);
        },
    });
    // commenting for future use 
    // const handleSelectApplication = (applicationId) => {
    //     let updatedApplications;

    //     if (formik.values.applications.includes(applicationId)) {
    //         updatedApplications = formik.values.applications.filter(item => item !== applicationId);
    //     } else {
    //         updatedApplications = [...formik.values.applications, applicationId];
    //     }
    //     formik.setFieldValue('applications', updatedApplications);
    // };
    const HandleColorLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setColorLogo(e.target.files[0]);
        };
    };
    const HandleWhiteLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setWhiteLogo(e.target.files[0]);
        };
    };
    const HandleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setOrganisationIcon(e.target.files[0]);
        };
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Create Organisation</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organisation Name</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Organisation Name"
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Organisation Email</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='email'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Organisation Email"
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Phone Number</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Phone Number'
                                    {...formik.getFieldProps('phone')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Mobile Number</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Mobile Number'
                                    {...formik.getFieldProps('mobile')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Address Line 1</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Address Line 1'
                                    {...formik.getFieldProps('address_1')}
                                />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Address Line 2</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Address Line 2'
                                    {...formik.getFieldProps('address_2')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Town</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Town'
                                    {...formik.getFieldProps('town')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>City</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='City'
                                    {...formik.getFieldProps('city')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Country</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('country')}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                >
                                    <option value=''>Select a Country...</option>
                                    {Array.isArray(countriesList) && countriesList.length > 0 ? (
                                        countriesList.map((country) => (
                                            <option key={country.id} value={country.id}>
                                                {country.country_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value='' disabled>No Country available</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Postcode</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Postcode'
                                    {...formik.getFieldProps('postcode')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Color Logo</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='file'
                                    className='form-control form-control-lg form-control-solid'
                                    onChange={(e) => HandleColorLogoChange(e)}
                                    name='picture'
                                />
                                <div className='text-danger mt-2'>
                                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>White Logo</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='file'
                                    className='form-control form-control-lg form-control-solid'
                                    onChange={(e) => HandleWhiteLogoChange(e)}
                                    name='picture'
                                />
                                <div className='text-danger mt-2'>
                                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Icon</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='file'
                                    className='form-control form-control-lg form-control-solid'
                                    onChange={(e) => HandleIconChange(e)}
                                    name='picture'
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Status</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('status')}
                                >
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Applications</span>
                            </label>
                            <div className='col-lg-8 fv-row row '>
                                {applicationList.map((application) => (
                                    <Card className={`col-lg-5 col-md-6 add-application-card mx-5 mt-2 ${formik.values.applications.includes(application.id) ? 'selected-application-card' : ''}`}
                                        onClick={() => handleSelectApplication(application.id)}
                                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}>
                                        <Card.Body>
                                            <Card.Title className='text-light'>{application.name}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-secondary">{application.description}</Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </div> */}
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button data-toggle="tooltip" data-placement="bottom" title="Add new Organisation" type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Add Organisation'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default AddOrganisation