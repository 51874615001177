import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify-token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const FORGET_PASSWORD_URL = `${API_URL}/forget-password`
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`
export const RESEND_EMAIL = `${API_URL}/email/resend`

// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post(LOGIN_URL, {
    email,
    password,
  })
  return response?.data
}

// Server should return AuthModel
export async function register(formData:object ) {
  const response = await axios.post(REGISTER_URL, formData)
  return response.data;
}
export async function get_all_countries() {
  const response = await axios.get(`${API_URL}/countries`)
  return response.data;
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(FORGET_PASSWORD_URL, {
    email,
  })
}
export async function resetPassword(token: string, email: string, password: string, password_confirmation: string,) {
  const response = await axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    email,
    password,
    password_confirmation,
    token,
  })
  return response?.data
}
export async function resendEmail(email: string) {
  const response = await axios.post(RESEND_EMAIL, {
    email,
  })
  return response?.data
}


export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
// Sign Out
export function signout(api_token: string) {
  const headers = {
    Authorization: `Bearer ${api_token}`,
  };
    return axios.post(`${API_URL}/logout`, {}, { headers });
}