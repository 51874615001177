import React, { useEffect, useState } from 'react'
import { OrganisationListInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

function ViewOrganisation() {
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface | null>();
    const location = useLocation();
    const organisation = (location.state as { organisation: OrganisationListInterface }).organisation;
    useEffect(() => {
        setSelectedOrganisation(organisation);
    }, [organisation]);
    const navigate = useNavigate();
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>View Organisation</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a Organisation'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Organisations"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/organisations/organisations-list')}
                    >
                        List of Organisations
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedOrganisation ? (
                        <>
                            {/* ... View Form ... */}
                            <form>
                                <div className='form-group row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Organisation Name</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedOrganisation.name}
                                        </label>
                                    </div>
                                </div>
                                <div className='form-group row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Organisation Email</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.email}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.phone}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Mobile Number</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.mobile}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Address Line 1</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.address_1}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Address Line 2</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.address_2}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Town</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.town}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>City</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.city}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Country</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.country_name}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Postcode</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {organisation.post_code}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span >Status</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        {selectedOrganisation.status === 1 ? (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Active
                                            </label>
                                        ) : (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                In Active
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Applications</span>
                            </label>
                            <div className='col-lg-8 fv-row row '>
                                {organisation.applications.map((application) => (
                                    <Card className={`col-lg-5 col-md-6 selected-application-card mx-5 mt-2`}
                                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}>
                                        <Card.Body>
                                            <Card.Title className='text-light'>{application.name}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-secondary">{application.description}</Card.Subtitle>
                                            <Card.Link href={application.website_url}>Take a Look</Card.Link>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </div>
                            </form>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ViewOrganisation