import { useEffect, useRef, useState } from 'react'
import { OrganisationListInterface } from '../../../modules/accounts/components/settings/SettingsModel'
import { useMutation, useQuery } from 'react-query'
import { delete_organisation, get_all_organisations } from '../api/OrganisationsApi'
import { Card } from 'react-bootstrap'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
interface User {
    access_token: string;
}

function OrganisationsList() {
    const [organisationsList, setOrganisationsList] = useState<OrganisationListInterface[]>([])
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface>()
    const navigate = useNavigate()
    const { data: organisationsData } = useQuery('organisations', get_all_organisations, {
        refetchOnWindowFocus: false,
    })
    // const iframeRef = useRef<HTMLIFrameElement | null>(null);

    // useEffect(() => {
    //     const iframe = iframeRef.current;

    //     // Check if iframe is loaded
    //     const handleLoad = () => {
    //         // Parse user from localStorage
    //         const user: User | null = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}');

    //         // Get token from user object
    //         const token = user?.access_token;

    //         console.log('Token:', token);  // Debugging purpose

    //         // Post token to iframe if exists
    //         if (token && iframe && iframe.contentWindow) {
    //             iframe.contentWindow.postMessage({ token: token }, 'https://multitenant.wrikooffice.staginghosts.com');
    //         } else {
    //             console.warn('Token not available or iframe not ready');
    //         }
    //     };

    //     if (iframe) {
    //         iframe.onload = handleLoad;
    //     }

    //     // Cleanup function
    //     return () => {
    //         if (iframe) {
    //             iframe.onload = null; // Remove the event listener on unmount
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (organisationsData) {
            setOrganisationsList(organisationsData.data as OrganisationListInterface[])
        }
    }, [organisationsData])
    const handleDeleteOrganisation = useMutation((id: number) => {
        return delete_organisation(id)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            })
            .then(() => {
                return get_all_organisations().then((data) => {
                    setOrganisationsList(data.data);
                })
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                }
            });
    });
    const handleViewApplications = (organisation: any) => {
        navigate("/organisations/applications", { state: { organisation: organisation } });
    };
    const handleOrganisationEdit = (organisation: any) => {
        navigate("/organisations/edit-organisation", { state: { organisation: organisation } });
    };
    const handleOrganisationUsers = (organisation: any) => {
        navigate("/organisations/organisation-users", { state: { organisation: organisation } });
    };
    return (
        <>
            <div className='row'>
                <div className='card-title mb-3 mx-3'>
                    {/* <iframe
                        ref={iframeRef}
                        src="https://multitenant.wrikooffice.staginghosts.com"
                        style={{ display: 'none' }} // Hidden iframe
                        title="Hidden Iframe"
                    /> */}
                    <h1 className='fw-bolder m-0'>My Organisations</h1>
                </div>
                {organisationsList.map((organisation, index) => (
                    <Card key={index} className={'organisation-card col-lg-3 m-2 bg-light'} onClick={() => handleViewApplications(organisation)}>
                        {/* <div className='position-relative'>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary position-absolute top-0 end-0 mt-2'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedOrganisation(organisation)
                                }}
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div> */}
                        <Card.Body className="d-flex flex-column">
                            <Card.Text>
                                <div className='d-flex justify-content-center mt-9'>
                                    {organisation && organisation.icon === null ? (
                                        <img
                                            alt='Logo'
                                            src={toAbsoluteUrl('/media/logos/wriko_central_icon.png')}
                                            className='h-25px app-sidebar-logo-default theme-light-show'
                                        />
                                    ) : (
                                        <img
                                            alt='Logo'
                                            src={organisation?.icon}
                                            className='h-25px app-sidebar-logo-default theme-light-show'
                                        />
                                    )}

                                </div>
                            </Card.Text>
                            <Card.Title className='d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark' >{organisation.name} {' '}</Card.Title>
                            <div className='d-none justify-content-center mt-auto organisation-setting-button'>
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                    className='btn btn-icon btn-sm btn-active-secondary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOrganisationEdit(organisation);
                                    }}>
                                    <KTIcon iconName='setting-2' className='fs-3 text-dark' />
                                </button>
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                    className='btn btn-icon btn-sm btn-active-secondary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOrganisationUsers(organisation);
                                    }}>
                                    <KTIcon iconName='user' className='fs-3 text-dark' />
                                </button>
                            </div>
                        </Card.Body>
                        {/* commenting for future use  */}
                        {/* <Card.Footer className='d-flex justify-content-end'>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                className='btn btn-icon btn-light-dark btn-active-color-success btn-sm me-2'
                                onClick={() => handleOrganisationEdit({ ...organisation })}
                            >
                                <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="See Organisation's Details"
                                onClick={() => handleOrganisationView(organisation)}
                                className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                                <KTIcon iconName='eye' className='fs-3' />
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete Organisation"
                                className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm me-2'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                onClick={() => setSelectedOrganisation(organisation)}
                            >
                                <KTIcon iconName='trash' className='fs-3' />
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                                className='btn btn-icon btn-light-dark btn-active-color-primary btn-sm me-2'
                            >
                                <KTIcon iconName='update-folder' className='fs-3' />
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Organisation Logs"
                                className='btn btn-icon btn-light-dark btn-active-color-info btn-sm me-2'
                            >
                                <i className='bi bi-journal-text'></i>
                            </button>
                        </Card.Footer> */}
                    </Card>
                ))}
                <Card className={'organisation-card col-lg-3 m-2 px-0 bg-light'}>
                    <Card.Body className='d-flex justify-content-center text-align-center'>
                        <Card.Text >
                            <div className='d-flex justify-content-center mt-7'>
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Create Organisation"
                                    className='btn btn-icon btn-dark btn-active-secondary btn-md me-2 round-button'
                                    onClick={() => {
                                        navigate("/organisations/create-organisation")
                                    }}
                                >
                                    <KTIcon iconName='plus' className='fs-3' />
                                </button>
                            </div>
                            <Card.Title className='d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark mt-1' >Create new Organisation </Card.Title>
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>

            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Organisation Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedOrganisation ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Organisation Name: </span> {selectedOrganisation.name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Emails: </span>{' '}
                                        {selectedOrganisation.email}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Organisation"
                                type='button'
                                // onClick={() => {
                                //     if (selectedOrganisation && selectedOrganisation.id) {
                                //         handleDeleteOrganisation.mutate(selectedOrganisation.id)
                                //     }
                                // }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Delete Organisation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganisationsList