import React, { ChangeEvent, useEffect, useState } from 'react';
import { OrganisationListInterface, SubscribedApplicationInterface, applicationsInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { edit_tenant_general_settings, get_organisation_tenant, update_application } from '../api/applicationsApi';
import { toast } from 'react-toastify';
import { update_organisation } from '../../organisations/api/OrganisationsApi';
import { Alert } from 'react-bootstrap';

function EditApplication() {
  const [selectedApplication, setSelectedApplication] = useState<SubscribedApplicationInterface | null>(null);
  const location = useLocation();
  const organisation = (location.state as { organisation: OrganisationListInterface }).organisation;
  const application = (location.state as { application: applicationsInterface }).application;

  const [docEncVarError, setDocEncVarError] = useState('');
  const [spacesFolderError, setSpacesFolderError] = useState('');
  const [domains, setDomains] = useState<{ frontend: string; api: string }[]>([]);
  const [ipAddresses, setIpAddresses] = useState<string[]>([]);
  const navigate = useNavigate();
  const userId: string = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.id || null;
  const [loading, setLoading] = useState(false);

  const { data, isLoading, isError } = useQuery(
    ['getOrganisationTenant', organisation.id, userId],
    () => get_organisation_tenant(organisation.id, userId, application.api_url),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setSelectedApplication(data.data[0] as SubscribedApplicationInterface);
      // Initialize domain pairs based on fetched data
      if (data.data[0]) {
        const domainPairs = data.data[0].frontend_domain.map((frontendDomain, index) => ({
          frontend: frontendDomain,
          api: data.data[0].domain_name[index] || '', // Ensure API domain exists
        }));
        setDomains(domainPairs);
        setIpAddresses(data.data[0].ip_address); // Initialize IP addresses
      }
    }
  }, [data]);


  const handleDocEncVarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDocEncVar = e.target.value;
    setDocEncVarError(newDocEncVar ? '' : 'Document Encryption Variable is required.');
    if (selectedApplication) {
      setSelectedApplication({
        ...selectedApplication,
        doc_encryption_variable: newDocEncVar,
      });
    }
  };

  const handleSpacesFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSpacesFolder = e.target.value;
    setSpacesFolderError(newSpacesFolder ? '' : 'Spaces Folder is required.');
    console.log(newSpacesFolder)
    if (selectedApplication) {
      setSelectedApplication({
        ...selectedApplication,
        spaces_folder: newSpacesFolder,
      });
    }
  };

  const handleAddDomain = () => {
    setDomains([...domains, { frontend: '', api: '' }]);
  };
  const handleFrontendDomainChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    if (selectedApplication) {
      const updatedFrontendDomain = [...selectedApplication.frontend_domain];
      updatedFrontendDomain[index] = value;

      const domainPairs = updatedFrontendDomain.map((frontendDomain, idx) => ({
        frontend: frontendDomain,
        api: domains[idx]?.api || '',
      }));

      setDomains(domainPairs);
      setSelectedApplication({
        ...selectedApplication,
        frontend_domain: updatedFrontendDomain,
      });
    }
  };

  const handleAPIDomainChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    if (selectedApplication) {
      const updatedAPIDomain = [...selectedApplication.domain_name];
      updatedAPIDomain[index] = value;

      const domainPairs = updatedAPIDomain.map((domainName, idx) => ({
        frontend: domains[idx]?.frontend || '',
        api: domainName,
      }));

      // Update the states
      setDomains(domainPairs);
      setSelectedApplication({
        ...selectedApplication,
        domain_name: updatedAPIDomain,
      });
    }
  };
  const handleRemoveDomain = (index: number) => {
    const updatedDomains = domains.filter((_, i) => i !== index);
    setDomains(updatedDomains);
  };
  const handleAddIpAddress = () => {
    setIpAddresses([...ipAddresses, '']); // Add an empty string for the new IP address
  };

  const handleRemoveIpAddress = (index: number) => {
    const newIpAddresses = ipAddresses.filter((_, i) => i !== index);
    setIpAddresses(newIpAddresses); // Remove the IP address at the specified index
  };

  const handleIpAddressChange = (index: number, value: string) => {
    const newIpAddresses = [...ipAddresses];
    newIpAddresses[index] = value; // Update the specific IP address
    setIpAddresses(newIpAddresses);
    if (selectedApplication) {
      setSelectedApplication({
        ...selectedApplication,
        ip_address: newIpAddresses,
      });
    }
  };

  const handleUpdateApplication = () => {
    try {
      setLoading(true);
      if (!selectedApplication) {
        throw new Error('No Application Selected for Update');
      }

      const {
        id,
        name,
        organization_id,
        user_id,
        domain_name,
        frontend_domain,
        ip_address,
      } = selectedApplication;
      const formData = new FormData();
      formData.append('name', name || '');
      formData.append('organization_id', organization_id || '');
      formData.append('application_id', String(application.id) || '');
      formData.append('is_ip_address', '1');
      formData.append('user_id', user_id || '');

      // Append applications array
      if (domain_name && domain_name.length > 0) {
        domain_name.forEach((domain, index) => {
          formData.append(`domain_name[${index}]`, domain);
        });
      }
      if (frontend_domain && frontend_domain.length > 0) {
        frontend_domain.forEach((domain, index) => {
          formData.append(`frontend_domain[${index}]`, domain);
        });
      }
      if (ip_address && ip_address.length > 0) {
        ip_address.forEach((ip, index) => {
          formData.append(`ip_address[${index}]`, ip);
        });
      }
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      updateApplicationMutation.mutate({
        id,
        formData,
      });
      editGeneralSettingsMutation.mutate({
        organization_id: organization_id,
        application_id: application.id,
        user_id: user_id,
        frontend_domain: frontend_domain[0],
        domain: domain_name[0]
      });
    } catch (error: any) {
      toast.error(error.message || 'An error occurred.', {
        position: 'top-right',
        autoClose: 3000,
      });
      setLoading(false);
    }
  };


  const updateApplicationMutation = useMutation((Data: any) => update_application(Data.id, Data.formData, application.api_url), {
    onSuccess: (response) => {
      setLoading(false);
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  const editGeneralSettingsMutation = useMutation(edit_tenant_general_settings, {
    onSuccess: (response) => {
      setLoading(false);
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit Subscribed Application</span>
        </h3>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedApplication ? (
            <>
              <form>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Domains</label>
                <div className='border border-primary rounded p-3 px-5 m-6'>
                  <div className='row mb-6'>
                    {/* Frontend Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6 mb-3'>
                      Frontend Domain 1
                    </label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="Frontend Domain"
                        value={selectedApplication.frontend_domain ? selectedApplication.frontend_domain[0] : ''}
                        onChange={(e) => handleFrontendDomainChange(e, 0)} // Pass index to the function
                      />
                    </div>

                    <div className='col-lg-2'>
                      <button
                        className='btn btn-info fs-8'
                        type='button'
                      >
                        Verify Domain Name
                      </button>
                    </div>
                    {/* API Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      API Domain 1
                    </label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="API Domain"
                        value={selectedApplication.domain_name ? selectedApplication.domain_name[0] : ''}
                        onChange={(e) => handleAPIDomainChange(e, 0)}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    {/* Frontend Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6 mb-3'>
                      Frontend Domain 2
                    </label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="Frontend Domain"
                        value={selectedApplication.frontend_domain ? selectedApplication.frontend_domain[1] : ''}
                        onChange={(e) => handleFrontendDomainChange(e, 1)} // Pass index to the function
                      />
                    </div>

                    {/* API Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      API Domain 2
                    </label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="API Domain"
                        value={selectedApplication.domain_name ? selectedApplication.domain_name[1] : ''}
                        onChange={(e) => handleAPIDomainChange(e, 1)}
                      />
                    </div>
                  </div>
                  <Alert variant="danger">
                    <p>
                      The first sub-domain is compulsory and after adding it, please contact our team to set up this sub-domain for you.
                      Our devops department will provide the information of the DNS details which will be used to point out your sub-domains.
                    </p>
                  </Alert>
                </div>
                {/* Other fields... */}
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>IP Addresses</label>
                <div className='border border-danger rounded p-3 px-5 m-6'>
                  {ipAddresses.map((ipAddress, index) => (
                    <div className='form-group row mb-6' key={index}>
                      <label className='col-lg-2 col-form-label fw-bold fs-6'>
                        <span className='required'>IP Address {index + 1}</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          value={ipAddress}
                          onChange={(e) => handleIpAddressChange(index, e.target.value)}
                        />
                      </div>
                      <div className='col-lg-2'>
                        <button
                          className='btn btn-danger mt-3'
                          type='button'
                          onClick={() => handleRemoveIpAddress(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className='col-lg-2'>
                    <button
                      type='button'
                      className='btn btn-success mt-3'
                      onClick={handleAddIpAddress}
                    >
                      Add IP Address
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : isLoading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div>Please Subscribe to this Application first</div>
          )}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {docEncVarError || spacesFolderError ? (
            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Application" className='btn btn-primary disabled'>
              Update Application
            </button>
          ) : (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Update Application"
              type='button'
              className='btn btn-primary'
              onClick={handleUpdateApplication}
              disabled={loading}
            >
              {!loading && 'Update Application'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditApplication;
