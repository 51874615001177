import React from "react";
import {
    ChartsWidget1,
    ListsWidget5,
    TablesWidget1,
    TablesWidget5,
  } from '../../../_metronic/partials/widgets'
export function OrdersList() {
    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-10">
                <li className="breadcrumb-item pe-3">
                    <a href="#" className="pe-3">
                        Orders
                    </a>
                </li>
                <li className="breadcrumb-item px-3 text-muted">Orders List</li>
            </ol>
            <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </>
    );
}