import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
// Password change
export const changePassword = async (currentPassword, newPassword, confirmPassword) => {
    const response = await axios.post(`${baseURL}/change-password`, {
        old_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmPassword
    });
    return response.data;
  };